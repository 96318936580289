import WasmController from "react-lib/frameworks/WasmController";
// apis
import DoctorNoteOrderDetail from "issara-sdk/apis/DoctorNoteOrderDetail_apps_DPO";
import DoctorNoteOrderList from "issara-sdk/apis/DoctorNoteOrderList_apps_DPO";
import UserPermissionView from "issara-sdk/apis/UserPermissionView_users";

// Interface
import { State as MainState } from "../../../../../HIS/MainHISInterface";

export type State = Partial<{
  // CommonInterface
  selectedDivision?: any;
  goToMenu?: any;
  planningData?: any;
  // common
  selectedEmr?: any;

  // permission
  drNoteOrderPermission?: any;

  // sequence
  DoctorNoteOrderSequence?: {
    sequenceIndex?: string | null;

    // data
    id?: number | null;
    detail?: string;
    is_out_perform_div: boolean;
    note?: string;
    out_perform_div?: number | null;
    plan_item?: any;

    // config
    readOnly?: boolean;
    chkCont: { checked: boolean; enabled: boolean };
    selected_out_perform_div?: number | null;
  } | null;

  // options
  drNoteDivisionOptions?: any[];
  // แก้ไข doctornoteorder
  doctorNoteOrderEditId?: number | null;
}>;

export const StateInitial: State = {
  // permission
  drNoteOrderPermission: null,

  // sequence
  DoctorNoteOrderSequence: {
    sequenceIndex: null,

    // data
    id: null,
    detail: "",
    is_out_perform_div: false,
    note: "",
    out_perform_div: null,
    plan_item: null,

    // config
    readOnly: false,
    chkCont: { checked: false, enabled: true },
    selected_out_perform_div: null,
  },

  // options
  drNoteDivisionOptions: [],
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
  masterData?: any;
};

export const DataInitial = {};

type Picked = Partial<
  Pick<
    MainState,
    | "buttonLoadCheck"
    | "errorMessage"
    | "successMessage"
    | "preOrderList"
  >
>;

type Handler<P = any, R = any> = (
  controller: WasmController<State & Picked, Event, Data>,
  params: P
) => R;

export const InitData: Handler = async (controller, params) => {
  const getPermission = UserPermissionView.post({
    apiToken: controller.apiToken,
    data: {
      config_core_ENABLE_OUT_PERFORM_DIV: false,
    },
  });

  const [permission] = await Promise.all([
    getPermission,
  ]);

  const state = controller.getState()

  controller.setState({
    DoctorNoteOrderSequence: {
      sequenceIndex: "AddOrEdit",

      // data
      id: null,
      detail: "",
      is_out_perform_div: false,
      note: "",
      out_perform_div: null,
      plan_item: null,

      // config
      readOnly: false,
      chkCont: { checked: false, enabled: true },
      selected_out_perform_div: null,
    },
    drNoteOrderPermission: permission[0],
    drNoteDivisionOptions: controller.data.masterData.division || [],
    planningData: {}
  },()=>{
    // เมื่อมีการกด edit มาจากรายการ doctornote tab order summary
    if (params.editId || !!state.doctorNoteOrderEditId) {
      controller.setState({ doctorNoteOrderEditId: null })

      AddOrEdit(controller, { action: "FETCH", pk: params.editId || state.doctorNoteOrderEditId });
    }
  });
};

export const AddOrEdit: Handler = async (controller, params) => {
  var state = controller.getState();

  if (!state.DoctorNoteOrderSequence || !state.selectedEmr) {
    return;
  }

  if (params?.action === "FETCH") {
    const [respFet, errFet, _netwFet] = await DoctorNoteOrderDetail.retrieve({
      pk: params.pk,
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });

    if (!errFet) {
      const selectedOutPerformDiv = state.drNoteDivisionOptions?.filter(
        (item: any) => item?.id === respFet?.out_perform_div
      );

      controller.setState({
        DoctorNoteOrderSequence: {
          sequenceIndex: "AddOrEdit",
          ...respFet,
          readOnly: respFet?.nurse_status !== "รอรับคำสั่ง",
          chkCont: {
            checked: respFet?.plan_item?.date_time_summary,
            enabled: false,
          },
          selected_out_perform_div: selectedOutPerformDiv?.[0],
        },
      });
    }
  } else if (params?.action === "REQUEST") {
    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [params.card]: "LOADING" },
    });

    let data: any = {
      ...state.DoctorNoteOrderSequence,
      emr: state.selectedEmr?.id,
      action: params.action,
    };
    if (params.createAppointment) {
      data.order_status = "1";
      data.order_payment_status = "1";
      data.order_type = "DIRECT";
    }
    const [respReq, errReq, _netwReq] = await DoctorNoteOrderList.create({
      data: data,
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });
    if (errReq) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: errReq },
        buttonLoadCheck: { ...state.buttonLoadCheck, [params.card]: "ERROR" },
      });
    } else {
      controller.setState({
        buttonLoadCheck: { ...state.buttonLoadCheck, [params.card]: "SUCCESS" },
        // #62981
        DoctorNoteOrderSequence: {
          ...state.DoctorNoteOrderSequence,
          detail: "",
        },
        ...(state.selectedDivision.code === "OPD" && {
          goToMenu: "OPD Summary"
        })
      });

      HandleSaveSuccess(controller, respReq, params);
      // #62981
      // AddOrEdit(controller, { action: "FETCH", pk: respReq.id });
    }
  } else if (params?.action === "EDIT") {
    controller.setState({
      buttonLoadCheck: { ...state.buttonLoadCheck, [params.card]: "LOADING" },
    });

    let data: any = {
      ...state.DoctorNoteOrderSequence,
      emr: state.selectedEmr?.id,
      action: params.action,
    };
    if (params.createAppointment) {
      data.order_status = "1";
      data.order_payment_status = "1";
      data.order_type = "DIRECT";
    }

    const [respEdit, errEdit, _netwEdit] = await DoctorNoteOrderDetail.update({
      pk: state.DoctorNoteOrderSequence.id,
      data: data,
      extra: { division: controller.data.division },
      apiToken: controller.apiToken,
    });
    if (errEdit) {
      controller.setState({
        errorMessage: { ...state.errorMessage, [params.card]: errEdit },
        buttonLoadCheck: { ...state.buttonLoadCheck, [params.card]: "ERROR" },
      });
    } else {
      controller.setState({
        buttonLoadCheck: { ...state.buttonLoadCheck, [params.card]: "SUCCESS" },
        goToMenu: "OPD Summary",
      });
      HandleSaveSuccess(controller, respEdit, params);
      AddOrEdit(controller, { action: "FETCH", pk: respEdit.id });
    }
  }
};

const HandleSaveSuccess: Handler = (controller: any, response: any, params: any) => {
  const state = controller.getState();

  // - Update preOrderList หน้า คำสั่งแพทย์ สำหรับนัดหมาย
  let preOrderList = [...(state.preOrderList || [])].filter((order) => order.id !== response.id);

  preOrderList.push({
    ...response,
    summary_detail: response.detail,
    type: "doctornoteorder",
  });

  controller.setState({ preOrderList: preOrderList });
  params.onSuccess?.();
}