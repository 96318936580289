import WasmController from "../../../../frameworks/WasmController";
import { State as MainState } from "../../../../../HIS/MainHISInterface";
// APIs
import PhysicalExamTemplateList from "issara-sdk/apis/PhysicalExamTemplateList_apps_DPO";
import AdmissionList from "issara-sdk/apis/AdmissionList_apps_DPI";
import TriageFromEncounter from "issara-sdk/apis/TriageFromEncounter_core";
import FormDataLatest from "issara-sdk/apis/FormDataLatest_apps_PTM";
import AdverseReactionList from "issara-sdk/apis/AdverseReactionList_apps_ADR";
import VitalSignValueList from "issara-sdk/apis/VitalSignValueList_apps_PTM";
import MedicationReconciliationList from "issara-sdk/apis/MedicationReconciliationNewList_apps_TPD";
//
import FormAdmission from "react-lib/apps/HISV3/DPO/pdfForm/FormAdmission";
import OperatingOrderDetailByEmr from "issara-sdk/apis/OperatingOrderDetailByEmr_apps_ORM";

import getPdfMake from "react-lib/appcon/common/pdfMake";
import { GetFormHeaderDetail } from "react-lib/apps/HISV3/ORM/sequence/PreOperation";
import { GetMeasurementData } from "react-lib/apps/HISV3/ANE/sequence/PreAnesthetic";
import moment from "moment";
import { adToBe } from "react-lib/utils/dateUtils";
import { alcoholOptions, tobaccoOptions } from "../../ADM/sequence/PreAssessment";

export type State = {
  selectedPatient?: any;
  selectedEncounter?: any;
  AssessmentSequence?: any;
  CreateUpdateEncounterSequence?: any;
  modReMedication?: any;

  PhysicalExamSequence?: {
    sequenceIndex?: string | null;

    organList?: any[];
    templateList?: any[];
    selectedTemplateList?: any[];
    otherExam?: any;
  } | null;
};

export const StateInitial: State = {
  PhysicalExamSequence: {
    sequenceIndex: null,

    organList: [],
    templateList: [],
    selectedTemplateList: [],
    otherExam: {},
  },
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: {} };

export type Data = {
  division?: number;
  masterData?: Record<string, any>;
};

type Picked = Partial<
  Pick<
    MainState,
    "buttonLoadCheck" | "errorMessage" | "successMessage" | "masterOptions" | "django"
  >
>;

export const DataInitial = {};

type Handler<P = any, R = any> = (
  controller: WasmController<State & Picked, Event, Data>,
  params: P
) => R;

export const Start: Handler = async (controller, params) => {
  const state = controller.getState();

  controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [["arriveStatus", {}]],
    },
  });

  controller.setState(
    {
      PhysicalExamSequence: {
        ...state.PhysicalExamSequence,

        sequenceIndex: "Action",
      },
    },
    () => {
      controller.handleEvent({ message: "RunSequence", params: { ...params } });
    }
  );
};

export const Action: Handler = async (controller, params) => {
  if (params?.action === "GET_TEMPLATE") {
    const [resp, error, netw] = await PhysicalExamTemplateList.list({
      apiToken: controller.apiToken,
      extra: { division: controller.data.division },
    });

    const state = controller.getState();

    if (error) {
      controller.setState({
        PhysicalExamSequence: {
          ...state.PhysicalExamSequence,
          templateList: [],
          selectedTemplateList: [],
        },
      });
    } else {
      const selectedTemplate: any[] = params.templates?.map((item: any) => {
        return { ...item, emr: params.emr };
      });

      const templateList = resp?.items?.map((tempList: any) => {
        const matchingTemp = params.templates?.find((selTemp: any) => selTemp.id === tempList.id);
        let resultItem = tempList?.items?.map((item: any) => {
          const matchingOrgan = state.PhysicalExamSequence?.organList?.find(
            (selTemp: any) => selTemp.organ === item.organ
          );
          return { ...item, ...matchingOrgan };
        });
        return { ...tempList, ...matchingTemp, items: resultItem };
      });

      controller.setState({
        PhysicalExamSequence: {
          ...state.PhysicalExamSequence,
          templateList: templateList,
          selectedTemplateList: selectedTemplate,
        },
      });
    }
  } else if (params?.action === "SET_EXAM") {
    const state = controller.getState();

    let organList = state.PhysicalExamSequence?.organList?.map((item: any) => item.organ);
    let templateResult = state.PhysicalExamSequence?.templateList;
    if (organList?.includes(params.organ.organ)) {
      templateResult = templateResult?.map((temp: any) => {
        let resultItem = temp.items.map((item: any) => {
          if (item.organ === params.organ.organ) {
            return { ...item, [params.data.name]: params.data.value };
          } else {
            return { ...item };
          }
        });
        return { ...temp, items: resultItem };
      });
    }

    let selectedResult = state.PhysicalExamSequence?.selectedTemplateList?.map((temp: any) => {
      let resultItem = temp.items.map((item: any) => {
        if (item.organ === params.organ.organ) {
          return { ...item, [params.data.name]: params.data.value };
        } else {
          return { ...item };
        }
      });
      return { ...temp, items: resultItem };
    });

    let organResult = state.PhysicalExamSequence?.organList?.map((item: any) => {
      if (item.organ === params.organ.organ) {
        return { ...item, [params.data.name]: params.data.value };
      } else {
        return { ...item };
      }
    });

    controller.setState({
      PhysicalExamSequence: {
        ...state.PhysicalExamSequence,
        templateList: templateResult,
        selectedTemplateList: selectedResult,
        organList: organResult,
      },
    });
  } else if (params?.action === "printAdmission") {
    const state = controller.getState();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [params.buttonLoadKey]: "LOADING",
      },
    });

    const [
      [admissionRes, admissionErr, admissionNet],
      [triageRes, triageErr, triageNet],
      [pregnancyRes, pregnancyErr, pregnancyNet],
      [adrListRes, adrListErr, adrListNet],
      [vitalSignRes, vitalSignErr, vitalSignNet],
    ] = await Promise.all([
      AdmissionList.retrieve({
        pk: params.emrId,
        apiToken: controller.apiToken,
      }),
      TriageFromEncounter.retrieve({
        pk: params.encounterId || state.selectedEncounter?.id,
        apiToken: controller.apiToken,
      }),
      FormDataLatest.retrieve({
        apiToken: controller.apiToken,
        params: {
          encounter: params.encounterId || state.selectedEncounter?.id,
          form_code: "CardPregnancyAssessment",
          form_version: "1.0",
        },
        extra: { division: controller.data.division },
      }),
      AdverseReactionList.list({
        apiToken: controller.apiToken,
        params: {
          exclude_cancel: true,
          limit: 400,
          patient: params.patientData?.id || state.selectedPatient?.id,
        },
        extra: { division: controller.data.division },
      }),
      VitalSignValueList.list({
        params: {
          patient: params.patientData?.id || state.selectedPatient?.id,
          latest_measurement: true,
        },
        apiToken: controller.apiToken,
      })
    ]);

    const { headerDetail } = await GetFormHeaderDetail(controller as any, {
      encounterId: params.encounterId || state.selectedEncounter?.id,
      patientData: params.patientData || state.selectedPatient,
    });

    // set vital_signs
    const measurementData = await GetMeasurementData(controller, params);

    if (admissionRes) {
      let systemicReview = admissionRes.systemic_review?.items.map((item: any) => {
        return item.organ_choice_items
          .filter((data: any) => data.choice_name !== "อื่นๆ")
          .map((data: any) => {
            return `${data.choice_name} ${systemicReviewStatus[data.status]}`;
          });
      });

      let physicalExamination = admissionRes.physical_examination?.items.map((item: any) => {
        return {
          organName: item.organ_name,
          result: item.result,
          description: `${item.result} ${
            item.result !== "N/A" && item.description && `- ${item.description}`
          }`,
        };
      });

      let problemList = admissionRes.problem_list?.problem_info.split("\n");

      let printDate = moment();

      let patientAssessment = state.AssessmentSequence?.patientAssessment?.data;

      let allergy = adrListRes?.items
        .filter((item: any) => item.adr_short_info !== "")
        .map((item: any) => {
          return item?.adr_short_info;
        });

      const filterOptionsText = (options:any,value:string) => {
        return (options || []).filter((item:any) => item.value === value)?.[0]?.text || "-"
      }

      let selectedDrugs = [...(state.modReMedication?.selectedDrugs as any)].map((item:any) => {
        return item.name
      });

      let data = {
        detail: headerDetail,
        userName: state.django.user.full_name || "-",
        printDate: `${adToBe(printDate?.format("DD/MM/YYYY"))} [${printDate.format("HH:mm")}]`,
        //
        ...admissionRes,
        infoReliability: filterOptionsText(infoReliabilityOptions, admissionRes?.admission_form?.info_reliability) || "-",
        systemicReview: systemicReview,
        physicalExamination: physicalExamination.filter((item: any) => item.result !== "N/A"),
        problemList: problemList,
        //
        arriveStatus:
          arriveOptions.find((item: any) => item.value === patientAssessment?.arrive_status)?.text ||
          "",
        chiefComplaint: patientAssessment?.chief_complaint,
        triageLevel: patientAssessment?.triage_level,
        pregnancyStatue: pregnancyStatue[pregnancyRes?.data?.pregnancy_status] || "-",
        underlyingDisease: state.CreateUpdateEncounterSequence?.underlying_disease || "-",
        allergy: allergy.join(", ") || "-",
        vitalSign: measurementData,
        bodyTemperature: (vitalSignRes?.items || [])?.filter((item:any) => item.name === "Body temperature")?.[0],
        painScore: (vitalSignRes?.items || [])?.filter((item:any) => item.name === "Pain Score")?.[0],
        tobacco: filterOptionsText(tobaccoOptions, patientAssessment?.tobacco) || "-",
        alcohol: filterOptionsText(alcoholOptions, patientAssessment?.alcohol) || "-",
        curentMedication: (selectedDrugs || []).join(", ") || "-",
      };

      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "SUCCESS",
        },
      });

      let docDef = await FormAdmission({ ...data });

      return (await getPdfMake(true)).createPdf(docDef).open();
    } else {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [params.buttonLoadKey]: "ERROR",
        },
      });
    }
  }
};

const systemicReviewStatus = {
  X: "N/A",
  Y: "มี",
  N: "ไม่มี",
};

const arriveOptions = [
  { key: "WLK", value: "WLK", text: "Walk-in" },
  { key: "APP", value: "APP", text: "นัดหมาย" },
  { key: "REF", value: "REF", text: "Refer-in" },
  { key: "CON", value: "CON", text: "Consult" },
  { key: "TRF", value: "TRF", text: "ถูกส่งมาจากหน่วยงานอื่น" },
];

const pregnancyStatue = {
  1: "ไม่ทราบ",
  2: "ไม่ตั้งครรภ์",
  3: "กำลังตั้งครรภ์",
};

const infoReliabilityOptions = [
  { text: "มาก", id: "HIGH", value: "HIGH" },
  { text: "ปานกลาง", id: "MEDIUM", value: "MEDIUM" },
  { text: "น้อย", id: "LOW", value: "LOW" },
  { text: "ประเมินไม่ได้", id: "NA", value: "NA" },
]