import WasmController from "../../../../frameworks/WasmController";

import PatientServiceTimeTrackingList from "issara-sdk/apis/PatientServiceTimeTrackingList_core";
import DrugOrderServiceTimeTrackingList from "issara-sdk/apis/DrugOrderServiceTimeTrackingList_apps_TPD";
import UserPermissionView from "issara-sdk/apis/UserPermissionView_users";
import { dateToStringWithoutTimeBE } from "react-lib/utils/dateUtils";
export type State = {
  TimeTrackingSequence?: {
    sequenceIndex?: string | null;
    patientServiceTimeTracking?: any;
    drugServiceTimeTracking?: any;
    opdEncounterExpire?: number;
    interval?: any;
    filters: {
      date?: string | null;
      divisionId?: number | null;
      doctorId?: number | null;
      patientSearch?: string | null;
      patientId?: number | null;
    };

    searchLoading: boolean;
  };
};

export const StateInitial: State = {
  TimeTrackingSequence: {
    sequenceIndex: null,
    patientServiceTimeTracking: null,
    drugServiceTimeTracking: null,
    opdEncounterExpire: 24,
    interval: null,
    filters: {
      date: "",
      divisionId: null,
      doctorId: null,
      patientSearch: "",
      patientId: null,
    },
    searchLoading: false,
  },
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
};

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;
export const Start: Handler = async (controller, params) => {

  console.log(" START !!! params: ", params);

  const getPermission = UserPermissionView.post({
    apiToken: controller.apiToken,
    data: {
      config_core_OPD_ENCOUNTER_EXPIRE: false,
    },
  });

  const [permission] = await Promise.all([
    getPermission,
  ]);
  
  console.log('permission: ', permission);

  controller.setState(
    {
      TimeTrackingSequence: {
        ...StateInitial.TimeTrackingSequence,
        opdEncounterExpire : permission?.[0]?.config_core_OPD_ENCOUNTER_EXPIRE || 24,
        filters: {date: dateToStringWithoutTimeBE(new Date())},
        sequenceIndex: "Action",
      },
    },
    () => {
      controller.handleEvent({
        message: "RunSequence",
        params: {
          ...params,
          action: "SEARCH",
        },
      });
    }
  );
};

export const Action: Handler = async (controller, params) => {
  if (params?.action === "SEARCH") {
    let { TimeTrackingSequence } = controller.getState();

    if (!TimeTrackingSequence?.filters?.date) {
      console.log(" Skip call ServiceTimeTrack base on no Date Selected");
      return;
    }
    let state = controller.getState() 

    let interval = state.TimeTrackingSequence?.interval 
    clearInterval(interval)
    
    controller.setState({
      TimeTrackingSequence: {
        ...TimeTrackingSequence,
        searchLoading: true,
        interval: null
      },
    });

    let divisionId =
      TimeTrackingSequence?.filters?.divisionId === 0
        ? null
        : TimeTrackingSequence?.filters?.divisionId;
    let doctorId =
      TimeTrackingSequence?.filters?.doctorId === 0
        ? null
        : TimeTrackingSequence?.filters?.doctorId;

    let [resP, errP, netP] = await PatientServiceTimeTrackingList.get({
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
      params: {
        date: TimeTrackingSequence?.filters?.date,
        division: TimeTrackingSequence?.filters?.divisionId !== 0 ? TimeTrackingSequence?.filters?.divisionId : null,
        doctor: TimeTrackingSequence?.filters?.doctorId !== 0 ? TimeTrackingSequence?.filters?.doctorId : null,
        // patient_search: TimeTrackingSequence?.filters?.patientSearch, ใช้ search patient แบบ เภสัช จะได้ patientId มาเสมอ
        patient: TimeTrackingSequence?.filters?.patientId,
      },
    });

    let [resD, errD, netD] = await DrugOrderServiceTimeTrackingList.get({
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
      params: {
        date: TimeTrackingSequence?.filters?.date,
        drug_order_type: "HOME_OPD",
        division: divisionId,
        doctor: doctorId,
        // patient_search: TimeTrackingSequence?.filters?.patientSearch, ใช้ search patient แบบ เภสัช จะได้ patientId มาเสมอ
        patient: TimeTrackingSequence?.filters?.patientId,
      },
    });

    state = controller.getState();

    if (!errP || !errD) {

      // let myInterval = setInterval(() => {
      //   Action(controller,params); 
      // }, 10000);

      controller.setState({
        TimeTrackingSequence: {
          ...state.TimeTrackingSequence,
          ...(!errP && { patientServiceTimeTracking: resP }),
          ...(!errD && { drugServiceTimeTracking: resD }),
          searchLoading: false,
          // interval: myInterval 
        },
      })

    } else {
      controller.setState({
        TimeTrackingSequence: {
          ...state.TimeTrackingSequence,
          patientServiceTimeTracking: null,
          drugServiceTimeTracking: null,
          searchLoading: false,
          interval: null
        },
      });
    }
  }
};
