import WasmController from "react-lib/frameworks/WasmController";
// import TreatmentList from "issara-sdk/apis/TreatmentList_apps_TRT";
import TreatmentOrderEstimate from "issara-sdk/apis/TreatmentOrderEstimate_apps_TRT";
import TreatmentOrderList from "issara-sdk/apis/TreatmentOrderList_apps_TRT";
import TreatmentOrderTemplateList from "issara-sdk/apis/TreatmentOrderTemplateList_apps_TRT";
// import { Item } from 'semantic-ui-react';
import icd9cmKeyUp from "issara-sdk/apis/icd9cmKeyUp_core";
import TreatmentOrderDetail from "issara-sdk/apis/TreatmentOrderDetail_apps_TRT";
import DoctorOrderList from "issara-sdk/apis/DoctorOrderList_apps_DPO";
import DoctorOrderCancel from "issara-sdk/apis/DoctorOrderCancel_apps_DPO";
import DoctorPendingTaskList from "issara-sdk/apis/DoctorPendingTaskList_apps_DPO";
import TreatmentOrderEducationApproveView from "issara-sdk/apis/TreatmentOrderEducationApproveView_apps_TRT";
import { formatTimeCurrent } from "react-lib/utils/dateUtils";

export type State = {
  buttonLoadCheck?: any;
  preOrderList?: any;
  selectedEncounter?: any;
  selectedEmr?: any;
  selectedProgressCycle?: any;
  searchedItemList?: any[];
  successMessage?: any;
  errorMessage?: any;
  procedureItems?: any[] | null;
  secondaryItems?: any[] | null;
  patientData?: any;
  treatmentOrderEditId?: number | null;
  isStudentUser?: boolean;

  OrderTreatmentSequence?: {
    sequenceIndex?: string | null;
    searchTreatment?: string | null;
    searchTreatmentId?: number | null;
    treatmentQuantity?: string | null;
    eligibilityType?: number | null;
    alertMessage?: string | null;
    treatmentList?: any[];
    orderItems?: any[];
    isOpenError?: boolean;
    treatmentOrder?: any[];
    isAppointment?: boolean;
    OrderTreatments?: any[];

    // ICD primary
    medTermPrincipal?: string | null;
    ICD9CMTermPrincipal?: string | null;
    ICD9CMCodeprincipal?: string | null;
    loadingmedTermPrincipal?: boolean;
    loadingICD9CMTermPrincipal?: boolean;
    loadingICD9CMCodeprincipal?: boolean;
    originalICD9Principal?: {
      icd10_id?: string | null;
      icdcode?: string | null;
      medterm?: string | null;
      icdterm?: string | null;
      medterm_id?: number;
    } | null;
    diagnosisListPrincipal?: any[] | null;
    showSearchResult?: boolean;

    // ICD secondary
    medTermSecondary?: string | null;
    ICD9MTermSecondary?: string | null;
    ICD9MCodeSecondary?: string | null;
    loadingMedTermSecondary?: boolean;
    loadingICD9MTermSecondary?: boolean;
    loadingICD9MCodeSecondary?: boolean;
    originalICD9Secondary?: {
      icd10_id?: string | null;
      icdcode?: string | null;
      medterm?: string | null;
      icdterm?: string | null;
      medterm_id?: number;
    } | null;
    diagnosisListSecondary?: any[] | null;
    showSearchResultSecondary?: boolean;

    //pre order
    preOrderDate?: any[] | null;
    preOrderCheck?: boolean;

    selectedDoctorPendingTask?: any;
  } | null;
  treatmentOrderList?: {
    orderDetail?: string | null;
    toBePerformer?: string | null;
    equipmentDetail?: string | null;
    item?: any;
  };
  treatmentTemplateList?: any[];
};

export const StateInitial: State = {
  OrderTreatmentSequence: {
    sequenceIndex: null,
    searchTreatment: "",
    searchTreatmentId: null,
    treatmentQuantity: "",
    eligibilityType: null,
    alertMessage: "",
    treatmentList: [],
    orderItems: [],
    isOpenError: false,
    treatmentOrder: [],
    isAppointment: false,
    OrderTreatments: [],

    // ICD primary
    medTermPrincipal: null,
    ICD9CMTermPrincipal: null,
    ICD9CMCodeprincipal: null,
    loadingmedTermPrincipal: false,
    loadingICD9CMTermPrincipal: false,
    loadingICD9CMCodeprincipal: false,
    showSearchResult: false,
    originalICD9Principal: {
      icd10_id: null,
      icdcode: null,
      medterm: null,
      icdterm: null,
    },
    diagnosisListPrincipal: [],
    // ICD seconda: "",string;
    ICD9MTermSecondary: null,
    ICD9MCodeSecondary: null,
    loadingMedTermSecondary: false,
    loadingICD9MTermSecondary: false,
    loadingICD9MCodeSecondary: false,
    originalICD9Secondary: {
      icd10_id: null,
      icdcode: null,
      medterm: null,
      icdterm: null,
    },
    diagnosisListSecondary: [],

    selectedDoctorPendingTask: null,
  },
  treatmentOrderList: {
    orderDetail: null,
    toBePerformer: null,
    equipmentDetail: null,
    item: null,
  },
  successMessage: null,
  errorMessage: null,
  procedureItems: [],
  secondaryItems: [],
  treatmentTemplateList: [],
};

export type Event =
  | { message: "RunSequence"; params: {} }
  | { message: "GetMasterData"; params: any };

export type Data = {
  division?: number;
};

export const DataInitial = {
  treatmentQuantity: "",
  medTermPrincipal: null,
  ICD9CMTermPrincipal: null,
  ICD9CMCodeprincipal: null,
};

type Handler = (controller: WasmController<State, Event, Data>, params?: any) => any;

export const GetMaster: Handler = async (controller, params) => {
  console.log(" GetMaster");
  const state = controller.getState();

  if (!state.OrderTreatmentSequence || (!state.selectedEmr && !params.isNullEmr)) {
    console.log("GetMaster return");
    console.log("GetMaster state.OrderTreatmentSequence", state.OrderTreatmentSequence);
    console.log("GetMaster state.selectedEmr: ", state.selectedEmr);

    return;
  }

  await controller.handleEvent({
    message: "GetMasterData",
    params: {
      masters: [["claimTreatment", {}], ...(params.isOutPerformDiv ? [["divisionOpd", {}]] : [])],
    },
  });

  if (!params.isOutPerformDiv) {
    const [treatmentOrderRes] = await TreatmentOrderList.list({
      params: {
        encounter_id: state?.selectedEncounter?.pk,
      },
      apiToken: controller.apiToken,
      extra: { division: controller.data?.division },
    });

    let treatmentOrder: any[] = [];

    if (treatmentOrderRes) {
      treatmentOrder = treatmentOrderRes?.items.map((item: any) => ({
        ...item,
        detail: item.order_summary_detail,
      }));
    } else {
      const urlParams = state.selectedProgressCycle?.id
        ? {
            progression_cycle: state.selectedProgressCycle?.id,
            emr: state.selectedProgressCycle?.emr,
          }
        : {
            emr: state.patientData?.EMR?.emr_id,
          };

      const doctorOrder = await DoctorOrderList.list({
        params: urlParams,
        apiToken: controller.apiToken,
        extra: { division: controller.data?.division },
      });

      treatmentOrder = doctorOrder?.[0]?.items?.filter(
        (item: any) => item.specific_type_code === "treatmentorder"
      );
    }

    controller.setState(
      {
        OrderTreatmentSequence: {
          ...JSON.parse(JSON.stringify(StateInitial.OrderTreatmentSequence)),
          ...state.OrderTreatmentSequence,
          sequenceIndex: "SearchAndAdd",
          treatmentQuantity: "",
          searchTreatment: "",
          searchTreatmentId: null,
          OrderTreatments: treatmentOrder,
          treatmentList: [],
          diagnosisListPrincipal: [],
          diagnosisListSecondary: [],
          treatmentOrder: [],
        },
        treatmentOrderList: {},
      },
      () => {
        // เมื่อมีการกด edit มาจากรายการ treatment tab order summary
        // หรือ treatment verbal order history
        if (state.treatmentOrderEditId) {
          HandleGetTreatmentOrder(controller, {
            ...params,
            orderId: state.treatmentOrderEditId,
          });
        }
      }
    );
  } else {
    controller.setState(
      {
        OrderTreatmentSequence: {
          ...JSON.parse(JSON.stringify(StateInitial.OrderTreatmentSequence)),
          ...state.OrderTreatmentSequence,
          sequenceIndex: "SearchAndAdd",
          treatmentQuantity: "",
          searchTreatment: "",
          searchTreatmentId: null,
        },
        treatmentOrderList: {},
      },
      async () => {
        // เมื่อมีการกด edit มาจากรายการ treatment tab order summary
        // หรือ treatment verbal order history
        if (state.treatmentOrderEditId) {
          HandleGetTreatmentOrder(controller, {
            ...params,
            orderId: state.treatmentOrderEditId,
          });
        } else {
          HandleEditId(controller, params);
        }
      }
    );
  }
};

const HandleEditId: Handler = async (controller, params) => {
  let updatedOrder: any = {};

  if (params.editId) {
    const result = await TreatmentOrderList.list({
      apiToken: controller.apiToken,
      params: {
        id: params.editId,
      },
    });

    const detail = result[0]?.items?.[0] || {};

    updatedOrder = {
      detail,
      order: { preOrderCheck: detail.is_out_perform_div, preOrderDate: detail.out_perform_div },
      treatmentOrder: {
        treatmentOrderList: {
          equipmentDetail: detail.equipment_detail,
          orderDetail: detail.order_detail,
          toBePerformer: detail.to_be_performer,
        },
      },
    };
  }

  const orderDoctor = await controller.handleEvent({
    message: "GetAppointmentOrderDoctor",
    params: { orderBy: updatedOrder.detail?.order_by },
  });

  const state = controller.getState();

  controller.setState(
    {
      OrderTreatmentSequence: {
        ...state.OrderTreatmentSequence,
        ...updatedOrder.order,
        ...orderDoctor,
      },
      ...updatedOrder.treatmentOrder,
    },
    () => {
      if (!params.editId) {
        return;
      }

      let orderItems: Record<string, any>[] = updatedOrder?.detail?.order_items || [];

      orderItems = orderItems.map((item) => ({
        id: item.id,
        eligibility_type: item.eligibility_type,
        name_code: item.name_code,
        product: item.product,
        quantity: item.quantity,
      }));

      controller.handleEvent({
        message: "RunSequence" as any,
        params: {
          sequence: "OrderTreatment" as any,
          action: "evaluate",
          isNullEmr: params.isNullEmr,
          list: orderItems,
        },
      });
    }
  );
};

const HandleGetTreatmentOrder: Handler = async (controller, params) => {
  controller.setState({ treatmentOrderEditId: null });

  console.log("HandleGetTreatmentOrder", params);

  SearchAndAdd(controller, {
    action: "edit",
    item: { id: params.orderId },
  });
};

export const SearchAndAdd: Handler = async (controller, params) => {
  let state = controller.getState();

  if (!state.OrderTreatmentSequence || (!state.selectedEmr && !params.isNullEmr)) return;

  if (params?.action === "evaluate") {
    console.log("Evaluate");

    const orderItems =
      params?.list?.map((item: any) => ({
        id: null,
        product: item.product,
        quantity: item.quantity,
        eligibility_type: item.eligibility_type,
      })) || [];
    if (orderItems?.length > 0) {
      const result: any[] = await TreatmentOrderEstimate.post({
        data: {
          encounter: params.encounterId || state.selectedEncounter?.id,
          have_compensation: false,
          order_items: orderItems,

          // order_items: [
          //   {
          //     id: null,
          //     product: state?.OrderTreatmentSequence?.searchTreatmentId,
          //     quantity: state?.OrderTreatmentSequence?.treatmentQuantity,
          //     eligibility_type: state?.OrderTreatmentSequence?.eligibilityType,
          //   },
          // ],
        },
        apiToken: controller.apiToken,
        extra: { division: controller.data?.division },
      });
      if (!result[1]) {
        console.log("result", result);

        let treatmentPrice = (params?.list || []).map((item: any, index: number) => {
          return {
            ...item,
            ...result[0]?.orders?.[index],
          };
        });

        state = controller.getState();
        return controller.setState({
          OrderTreatmentSequence: {
            ...state.OrderTreatmentSequence,
            alertMessage: result[0]?.alert_message,
            searchTreatment: "",
            searchTreatmentId: null,
            treatmentQuantity: "",
            eligibilityType: null,
            treatmentList: treatmentPrice,
            orderItems: orderItems,
            treatmentOrder: result[0],
          },
          searchedItemList: [],
        });
      }
    }

    return controller.setState({
      OrderTreatmentSequence: {
        ...state.OrderTreatmentSequence,
        searchTreatment: "",
        searchTreatmentId: null,
        treatmentQuantity: "",
        eligibilityType: null,
        treatmentList: [...params?.list],
        orderItems: orderItems,
      },
      searchedItemList: [],
    });

    // if (!result[1] && result[0]?.alert_message) {
    //   console.log("result", result);

    //   state = controller.getState();
    //   controller.setState({
    //     OrderTreatmentSequence: {
    //       ...state.OrderTreatmentSequence,
    //       alertMessage: result[0]?.alert_message,
    //       searchTreatment: "",
    //       searchTreatmentId: null,
    //       treatmentQuantity: "",
    //       eligibilityType: null,
    //       treatmentList: [...params?.list],
    //       orderItems: orderItems,
    //       treatmentOrder: result[0],
    //     },
    //     searchedItemList: [],
    //   });
    // }
  } else if (params?.action === "save") {
    state = controller.getState();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "LOADING",
      },
    });

    const principal = state?.OrderTreatmentSequence?.diagnosisListPrincipal || [];
    const secondary = state?.OrderTreatmentSequence?.diagnosisListSecondary || [];
    const coreProcedure = [...principal, ...secondary];

    const timedefault = formatTimeCurrent(new Date());
    const orderTime = `${state?.OrderTreatmentSequence?.preOrderDate}-${timedefault}`;

    const orderItemsAdd = state?.OrderTreatmentSequence?.orderItems?.map((item: any) => ({
      product: item.product,
      quantity: item.quantity,
      eligibility_type: item.eligibility_type,
    }));

    const orderItemsEdit = state?.OrderTreatmentSequence?.treatmentList?.map((item: any) => ({
      product: item.product,
      quantity: item.quantity,
      eligibility_type: item.eligibility_type,
    }));

    const procedure = coreProcedure?.map((item: any) => ({
      active: item.active,
      created: item.created,
      edit_user: item.edited,
      emr: item.emr,
      icd9cm: item.icd9cm,
      icd9cm_code: item.icd9cm_code,
      icd9cm_med_term: item.medterm_id || null,
      icd9cm_term: item.icd9cm_term,
      id: item.id,
      medical_description: item.medterm_id ? item.icd9cm_med_term : item.medical_description,
      order: item.order,
      type: item.type,
    }));

    const data = {
      action: params.editId ? "EDIT" : "ORDER",
      claim_payload: null,
      core_procedure: params.editId ? procedure : coreProcedure,
      emr: state?.selectedEmr?.id || null,
      encounter: params.encounterId || state?.selectedEncounter?.pk,
      equipment_detail: state?.treatmentOrderList?.equipmentDetail,
      id: params.editId || null,
      is_advance: state?.OrderTreatmentSequence?.preOrderDate ? true : false,
      is_operation_division: false,
      is_out_perform_div: state.OrderTreatmentSequence?.preOrderCheck || false,
      note: "",
      order_detail: state?.treatmentOrderList?.orderDetail,
      order_items: params.editId ? orderItemsEdit : orderItemsAdd,
      order_status: params.orderStatus || 2,
      order_summary_detail: "",
      order_time: params.isOutPerformDiv
        ? null
        : state?.OrderTreatmentSequence?.preOrderDate
        ? orderTime
        : null,
      order_by: params.isAppointment ? params?.order_by : null,
      out_perform_div: params.isOutPerformDiv
        ? state?.OrderTreatmentSequence?.preOrderDate || ""
        : "",
      progression_cycle: state.selectedProgressCycle?.id,
      show_alert: params.isOutPerformDiv ? false : true,
      to_be_performer: state?.treatmentOrderList?.toBePerformer,
      _items: state?.OrderTreatmentSequence?.treatmentList,
      _principalDiag: [],
      _secondaryDiag: [],
      dataChanged: params.dataChanged,
    };

    let [response, error, network]: any = [null, null, null];

    if (params.editId) {
      [response, error, network] = await TreatmentOrderDetail.update({
        pk: params.editId,
        data,
        apiToken: controller.apiToken,
        extra: { division: controller.data?.division },
      });
    } else {
      [response, error, network] = await TreatmentOrderList.create({
        data,
        apiToken: controller.apiToken,
        extra: { division: controller.data?.division },
      });
    }

    if (error) {
      state = controller.getState();
      console.log("error", error);

      let toBePerformError = Object.keys(error).includes("to_be_performer")
        ? "จำเป็นต้องระบุผู้ทำหัตถการ"
        : "";
      return controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.isOutPerformDiv ? params.card : params.sequence]: error,
        },
        OrderTreatmentSequence: {
          ...state.OrderTreatmentSequence,
          isOpenError: true,
          ...(toBePerformError && { alertMessage: toBePerformError }),
        },
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "ERROR",
        },
      });
    } else {
      controller.handleEvent({
        message: "HandleRefreshEncounter" as any,
        params: {},
      });

      state = controller.getState();

      if (!params.isOutPerformDiv) {
        return controller.setState(
          {
            OrderTreatmentSequence: {
              ...state.OrderTreatmentSequence,
              treatmentList: [],
              orderItems: [],
              sequenceIndex: "START",
              preOrderCheck: false,
              preOrderDate: null,
            },
            successMessage: {
              ...state.successMessage,
              [params.sequence]: true,
            },
            buttonLoadCheck: {
              ...state.buttonLoadCheck,
              [`${params.card}_${params.action}`]: "SUCCESS",
            },
          },
          () =>
            controller.handleEvent({
              message: "RunSequence" as any,
              params: {
                sequence: "OrderTreatment" as any,
              },
            })
        );
      }

      controller.setState({
        successMessage: { ...state.successMessage, [params.sequence]: true },
        OrderTreatmentSequence: {
          ...state.OrderTreatmentSequence,
          treatmentList: [],
          orderItems: [],
          preOrderCheck: false,
          preOrderDate: null,
        },
        // errorMessage:{...state.errorMessage,[params.sequence] : null},
        treatmentOrderList: {
          equipmentDetail: null,
          orderDetail: null,
          toBePerformer: null,
        },
        ...(params.isOutPerformDiv
          ? {
              preOrderList: params.editId
                ? state.preOrderList.map((item: any) =>
                    item.id === params.editId ? { ...response, type: "treatmentorder" } : item
                  )
                : [...(state.preOrderList || []), { ...response, type: "treatmentorder" }],
            }
          : {}),
      });

      params.onSuccess?.();
    }
  } else if (params?.action === "add") {
    state = controller.getState();

    let diagnosis: any = [...(state?.OrderTreatmentSequence?.diagnosisListPrincipal || [])];
    const defaultList = {
      emr: state?.selectedEmr?.id,
      icd9cm: state?.OrderTreatmentSequence?.originalICD9Principal?.icd10_id,
      icd9cm_code: state?.OrderTreatmentSequence?.originalICD9Principal?.icdcode,
      icd9cm_med_term: state?.OrderTreatmentSequence?.originalICD9Principal?.medterm_id,
      icd9cm_term: state?.OrderTreatmentSequence?.originalICD9Principal?.icdterm,
      medical_description: state?.OrderTreatmentSequence?.originalICD9Principal?.medterm,
      medterm_id: state?.OrderTreatmentSequence?.originalICD9Principal?.medterm_id,
      type: "PRIMARY",
    };

    diagnosis.push(defaultList);
    controller.setState({
      OrderTreatmentSequence: {
        ...state.OrderTreatmentSequence,
        diagnosisListPrincipal: diagnosis,
        medTermPrincipal: null,
        ICD9CMTermPrincipal: null,
        ICD9CMCodeprincipal: null,
      },
    });
  } else if (params?.action === "searchPrincipal") {
    if (params?.name === "icdcode") {
      if (params?.value.length < 3) {
        controller.setState({
          OrderTreatmentSequence: {
            ...state.OrderTreatmentSequence,
            showSearchResult: false,
          },
          procedureItems: [],
        });
        return;
      }
      controller.setState({
        OrderTreatmentSequence: {
          ...state.OrderTreatmentSequence,
          loadingICD9CMCodeprincipal: true,
          showSearchResult: true,
        },
      });

      const icd9Search = await icd9cmKeyUp.post({
        method: params?.name,
        data: {
          icdcode: params?.value,
        },
        apiToken: controller.apiToken,
      });
      controller.setState({
        OrderTreatmentSequence: {
          ...state.OrderTreatmentSequence,
          loadingICD9CMCodeprincipal: false,
          showSearchResult: true,
        },
        procedureItems: icd9Search?.[0]?.response,
      });
    } else if (params?.name === "medterm") {
      if (params?.value.length < 3) {
        controller.setState({
          OrderTreatmentSequence: {
            ...state.OrderTreatmentSequence,
            showSearchResult: false,
          },
          procedureItems: [],
        });
        return;
      }
      controller.setState({
        OrderTreatmentSequence: {
          ...state.OrderTreatmentSequence,
          loadingmedTermPrincipal: true,
          showSearchResult: true,
        },
      });
      const icd9Search = await icd9cmKeyUp.post({
        method: params?.name,
        data: {
          medterm: params?.value,
        },
        apiToken: controller.apiToken,
      });
      controller.setState({
        OrderTreatmentSequence: {
          ...state.OrderTreatmentSequence,
          loadingmedTermPrincipal: false,
          showSearchResult: true,
        },
        procedureItems: icd9Search?.[0]?.response,
      });
    } else if (params?.name === "icdterm") {
      if (params?.value.length < 3) {
        controller.setState({
          OrderTreatmentSequence: {
            ...state.OrderTreatmentSequence,
            showSearchResult: false,
          },
          procedureItems: [],
        });
        return;
      }
      controller.setState({
        OrderTreatmentSequence: {
          ...state.OrderTreatmentSequence,
          loadingICD9CMTermPrincipal: true,
          showSearchResult: true,
        },
      });
      const icd9Search = await icd9cmKeyUp.post({
        method: params?.name,
        data: {
          icdterm: params?.value,
        },
        apiToken: controller.apiToken,
      });
      controller.setState({
        OrderTreatmentSequence: {
          ...state.OrderTreatmentSequence,
          loadingICD9CMTermPrincipal: false,
          showSearchResult: true,
        },
        procedureItems: icd9Search?.[0]?.response,
      });
    }
  } else if (params?.action === "select") {
    state = controller.getState();

    controller.setState({
      OrderTreatmentSequence: {
        ...state.OrderTreatmentSequence,
        medTermPrincipal: params?.value?.medterm,
        ICD9CMTermPrincipal: params?.value?.icdterm,
        ICD9CMCodeprincipal: params?.value?.icdcode,
        originalICD9Principal: params?.value,
        showSearchResult: false,
      },
    });
  } else if (params?.action === "searchSecondary") {
    if (params?.name === "icdcode") {
      if (params?.value.length < 3) {
        controller.setState({
          OrderTreatmentSequence: {
            ...state.OrderTreatmentSequence,
            showSearchResultSecondary: false,
          },
          secondaryItems: [],
        });
        return;
      }
      controller.setState({
        OrderTreatmentSequence: {
          ...state.OrderTreatmentSequence,
          loadingICD9MCodeSecondary: true,
          showSearchResultSecondary: true,
        },
      });

      const icd9Search = await icd9cmKeyUp.post({
        method: params?.name,
        data: {
          icdcode: params?.value,
        },
        apiToken: controller.apiToken,
      });

      controller.setState({
        OrderTreatmentSequence: {
          ...state.OrderTreatmentSequence,
          loadingICD9MCodeSecondary: false,
          showSearchResultSecondary: true,
        },
        secondaryItems: icd9Search?.[0]?.response,
      });
    } else if (params?.name === "medterm") {
      if (params?.value.length < 3) {
        controller.setState({
          OrderTreatmentSequence: {
            ...state.OrderTreatmentSequence,
            showSearchResultSecondary: false,
          },
          secondaryItems: [],
        });
        return;
      }
      controller.setState({
        OrderTreatmentSequence: {
          ...state.OrderTreatmentSequence,
          loadingMedTermSecondary: true,
          showSearchResultSecondary: true,
        },
      });
      const icd9Search = await icd9cmKeyUp.post({
        method: params?.name,
        data: {
          medterm: params?.value,
        },
        apiToken: controller.apiToken,
      });
      controller.setState({
        OrderTreatmentSequence: {
          ...state.OrderTreatmentSequence,
          loadingMedTermSecondary: false,
          showSearchResultSecondary: true,
        },
        secondaryItems: icd9Search?.[0]?.response,
      });
    } else if (params?.name === "icdterm") {
      if (params?.value.length < 3) {
        controller.setState({
          OrderTreatmentSequence: {
            ...state.OrderTreatmentSequence,
            showSearchResultSecondary: false,
          },
          secondaryItems: [],
        });
        return;
      }
      controller.setState({
        OrderTreatmentSequence: {
          ...state.OrderTreatmentSequence,
          loadingICD9MCodeSecondary: true,
          showSearchResultSecondary: true,
        },
      });
      const icd9Search = await icd9cmKeyUp.post({
        method: params?.name,
        data: {
          icdterm: params?.value,
        },
        apiToken: controller.apiToken,
      });
      controller.setState({
        OrderTreatmentSequence: {
          ...state.OrderTreatmentSequence,
          loadingICD9MCodeSecondary: false,
          showSearchResultSecondary: true,
        },
        secondaryItems: icd9Search?.[0]?.response,
      });
    }
  } else if (params?.action === "selectSecondary") {
    state = controller.getState();

    controller.setState({
      OrderTreatmentSequence: {
        ...state.OrderTreatmentSequence,
        medTermSecondary: params?.value?.medterm,
        ICD9MTermSecondary: params?.value?.icdterm,
        ICD9MCodeSecondary: params?.value?.icdcode,
        originalICD9Secondary: params?.value,
        showSearchResultSecondary: false,
      },
    });
  } else if (params?.action === "addSecondary") {
    state = controller.getState();

    let diagnosis: any = [...(state?.OrderTreatmentSequence?.diagnosisListSecondary || [])];
    const defaultList = {
      emr: state?.selectedEmr?.id,
      icd9cm: state?.OrderTreatmentSequence?.originalICD9Secondary?.icd10_id,
      icd9cm_code: state?.OrderTreatmentSequence?.originalICD9Secondary?.icdcode,
      icd9cm_med_term: state?.OrderTreatmentSequence?.originalICD9Secondary?.medterm_id,
      icd9cm_term: state?.OrderTreatmentSequence?.originalICD9Secondary?.icdterm,
      medical_description: state?.OrderTreatmentSequence?.originalICD9Secondary?.medterm,
      medterm_id: state?.OrderTreatmentSequence?.originalICD9Secondary?.medterm_id,
      type: "SECONDARY",
    };

    diagnosis.push(defaultList);
    controller.setState({
      OrderTreatmentSequence: {
        ...state.OrderTreatmentSequence,
        diagnosisListSecondary: diagnosis,
        medTermSecondary: null,
        ICD9MTermSecondary: null,
        ICD9MCodeSecondary: null,
      },
    });
  } else if (params?.action === "search") {
    const templateList = await TreatmentOrderTemplateList.list({
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    controller.setState({
      OrderTreatmentSequence: {
        ...state.OrderTreatmentSequence,
      },
      treatmentTemplateList: templateList?.[0]?.items,
    });
  } else if (params?.action === "selectTemplate") {
    state = controller.getState();

    let templateDate = params?.value?.template_items?.map((item: any) => {
      return {
        eligibility_type: state?.OrderTreatmentSequence?.eligibilityType || 1,
        name_code: item?.name_code,
        product: item?.product,
        quantity: item?.quantity || "1",
      };
    });

    let listPrincipalData = params?.value?.template_infos
      ?.filter((item: any) => item.type === "PRIMARY")
      ?.map((item: any) => {
        return {
          ...item,
          emr: state?.selectedEmr?.id,
          medical_description: "",
        };
      });

    let listSecondaryData = params?.value?.template_infos
      ?.filter((item: any) => item.type === "SECONDARY")
      ?.map((item: any) => {
        return {
          ...item,
          emr: state?.selectedEmr?.id,
        };
      });

    const treatmentList = [
      ...(state?.OrderTreatmentSequence?.treatmentList || []),
      ...templateDate,
    ];
    const orderItems = [...(state?.OrderTreatmentSequence?.orderItems || []), ...templateDate];
    const diagnosisListPrincipal = [
      ...(state?.OrderTreatmentSequence?.diagnosisListPrincipal || []),
      ...listPrincipalData,
    ];
    const diagnosisListSecondary = [
      ...(state?.OrderTreatmentSequence?.diagnosisListSecondary || []),
      ...listSecondaryData,
    ];

    controller.setState({
      OrderTreatmentSequence: {
        ...state.OrderTreatmentSequence,
        treatmentList: treatmentList,
        orderItems: orderItems,
        diagnosisListPrincipal: diagnosisListPrincipal,
        diagnosisListSecondary: diagnosisListSecondary,
      },
      treatmentOrderList: {
        orderDetail: params?.value?.order_detail,
        toBePerformer: params?.value?.to_be_performer,
        equipmentDetail: params?.value?.equipment_detail,
      },
    });

    controller.handleEvent({
      message: "RunSequence" as any,
      params: {
        sequence: "OrderTreatment" as any,
        action: "evaluate",
        isNullEmr: params.isNullEmr,
        list: orderItems,
      },
    });
  } else if (params.action === "clearMessage") {
    controller.setState({
      successMessage: { ...state.successMessage, [params.sequence]: null },
      errorMessage: { ...state.errorMessage, [params.sequence]: null },
    });
  } else if (params?.action === "removeDiagnosis") {
    state = controller.getState();

    let diagnosisPrincipal: any = [
      ...(state?.OrderTreatmentSequence?.diagnosisListPrincipal || []),
    ];
    let diagnosisSecondary: any = [
      ...(state?.OrderTreatmentSequence?.diagnosisListSecondary || []),
    ];

    if (params.value.type === "PRIMARY") {
      const diagnosisPrincipalFilter = diagnosisPrincipal?.filter(
        (acc: any, index: number) => index !== params.index
      );
      console.log("diagnosisPrincipalFilter", diagnosisPrincipalFilter);

      controller.setState({
        OrderTreatmentSequence: {
          ...state.OrderTreatmentSequence,
          diagnosisListPrincipal: diagnosisPrincipalFilter,
        },
      });
    } else if (params.value.type === "SECONDARY") {
      state = controller.getState();
      const diagnosisSecondaryFilter = diagnosisSecondary?.filter(
        (acc: any, index: number) => index !== params.index
      );
      controller.setState({
        OrderTreatmentSequence: {
          ...state.OrderTreatmentSequence,
          diagnosisListSecondary: [...diagnosisSecondaryFilter],
        },
      });
    }
  } else if (params?.action === "removeTreatmentList") {
    const treatmentLis: any = [...(state?.OrderTreatmentSequence?.treatmentList || [])];
    const treatmentLisFilter = treatmentLis?.filter(
      (acc: any, index: number) => index !== params.index
    );
    controller.setState({
      OrderTreatmentSequence: {
        ...state.OrderTreatmentSequence,
        sequenceIndex: "SearchAndAdd",
        treatmentList: treatmentLisFilter,
      },
    });
  } else if (params.action === "edit") {
    const treatmentOrder = await TreatmentOrderDetail.retrieve({
      pk: params.item?.id,
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });

    const notAllowEdit = !checkAllowEditOrder({
      ...(treatmentOrder || {}),
      isStudentUser: state.isStudentUser,
    });

    if (notAllowEdit) {
      return;
    }

    const coreProcedureSecondary = treatmentOrder?.[0]?.core_procedure
      ?.filter((acc: any) => acc.type === "SECONDARY" && acc.active)
      .map((item: any) => ({
        ...item,
        medical_description: item.icd9cm_med_term ? item.icd9cm_term : item.medical_description,
      }));

    const coreProcedurePrincipal = treatmentOrder?.[0]?.core_procedure
      ?.filter((acc: any) => acc.type === "PRIMARY" && acc.active)
      .map((item: any) => ({
        ...item,
        medical_description: item.icd9cm_med_term ? item.icd9cm_term : item.medical_description,
      }));

    state = controller.getState();

    controller.setState({
      OrderTreatmentSequence: {
        ...JSON.parse(JSON.stringify(StateInitial.OrderTreatmentSequence)),
        ...state.OrderTreatmentSequence,
        treatmentList: treatmentOrder?.[0]?.order_items,
        diagnosisListSecondary: coreProcedureSecondary,
        diagnosisListPrincipal: coreProcedurePrincipal,
        preOrderCheck: treatmentOrder?.[0]?.is_advance,
        preOrderDate: treatmentOrder?.[0]?.is_advance
          ? treatmentOrder?.[0]?.order_time?.split(" ")?.[0]
          : null,
      },
      treatmentOrderList: {
        toBePerformer: treatmentOrder?.[0]?.to_be_performer,
        orderDetail: treatmentOrder?.[0]?.order_detail,
        equipmentDetail: treatmentOrder?.[0]?.equipment_detail,
        item: treatmentOrder?.[0],
      },
    });

    controller.handleEvent({
      message: "RunSequence" as any,
      params: {
        sequence: "OrderTreatment" as any,
        action: "evaluate",
        isNullEmr: params.isNullEmr,
        list: treatmentOrder?.[0]?.order_items,
      },
    });
  } else if (params.action === "cancel") {
    const [r, e, n] = await DoctorOrderCancel.patch({
      pk: params.item?.id,
      data: {
        action: "REQUEST",
        order_status: "DRAFT",
        items: [{ id: params.item?.id }],
      },
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    });
    if (e) {
      controller.setState({
        errorMessage: {
          ...state.errorMessage,
          [params.card]: e,
        },
        OrderTreatmentSequence: {
          ...state.OrderTreatmentSequence,
        },
      });
    } else {
      controller.handleEvent({
        message: "HandleRefreshEncounter" as any,
        params: {},
      });

      controller.setState(
        {
          OrderTreatmentSequence: {
            ...state.OrderTreatmentSequence,
            sequenceIndex: "START",
          },
          successMessage: { ...state.successMessage, [params.card]: r },
        },
        () =>
          controller.handleEvent({
            message: "RunSequence" as any,
            params: {
              sequence: "OrderTreatment" as any,
            },
          })
      );
    }
  } else if (params.action === "getData") {
    const state = controller.getState();

    const [treatmentOrderRes] = await TreatmentOrderList.list({
      params: {
        encounter_id: state?.selectedEncounter?.pk,
      },
      apiToken: controller.apiToken,
      extra: { division: controller.data?.division },
    });

    let treatmentOrder: any[] = [];

    if (treatmentOrderRes) {
      treatmentOrder = treatmentOrderRes?.items.map((item: any) => ({
        ...item,
        detail: item.order_summary_detail,
      }));
    } else {
      const urlParams = state.selectedProgressCycle?.id
        ? {
            progression_cycle: state.selectedProgressCycle?.id,
            emr: state.selectedProgressCycle?.emr,
          }
        : {
            emr: state.patientData?.EMR?.emr_id,
          };

      const doctorOrder = await DoctorOrderList.list({
        params: urlParams,
        apiToken: controller.apiToken,
        extra: { division: controller.data?.division },
      });

      treatmentOrder = doctorOrder?.[0]?.items?.filter(
        (item: any) => item.specific_type_code === "treatmentorder"
      );
    }

    controller.setState({
      OrderTreatmentSequence: {
        ...state.OrderTreatmentSequence,
        OrderTreatments: treatmentOrder,
        treatmentList: [],
      },
    });
  } else if (params.action === "confirm") {
    const state = controller.getState();

    controller.setState({
      buttonLoadCheck: {
        ...state.buttonLoadCheck,
        [`${params.card}_${params.action}`]: "LOADING",
      },
    });

    let id = params.id;

    if (!params.id) {
      const principal = state?.OrderTreatmentSequence?.diagnosisListPrincipal || [];
      const secondary = state?.OrderTreatmentSequence?.diagnosisListSecondary || [];
      const coreProcedure = [...principal, ...secondary];

      const timedefault = formatTimeCurrent(new Date());
      const orderTime = `${state?.OrderTreatmentSequence?.preOrderDate}-${timedefault}`;

      const orderItemsAdd = state?.OrderTreatmentSequence?.orderItems?.map((item: any) => ({
        product: item.product,
        quantity: item.quantity,
        eligibility_type: item.eligibility_type,
      }));

      const orderItemsEdit = state?.OrderTreatmentSequence?.treatmentList?.map((item: any) => ({
        product: item.product,
        quantity: item.quantity,
        eligibility_type: item.eligibility_type,
      }));

      const procedure = coreProcedure?.map((item: any) => ({
        active: item.active,
        created: item.created,
        edit_user: item.edited,
        emr: item.emr,
        icd9cm: item.icd9cm,
        icd9cm_code: item.icd9cm_code,
        icd9cm_med_term: item.medterm_id || null,
        icd9cm_term: item.icd9cm_term,
        id: item.id,
        medical_description: item.medterm_id ? item.icd9cm_med_term : item.medical_description,
        order: item.order,
        type: item.type,
      }));

      const data = {
        action: params.editId ? "EDIT" : "ORDER",
        claim_payload: null,
        core_procedure: params.editId ? procedure : coreProcedure,
        emr: state?.selectedEmr?.id || null,
        encounter: params.encounterId || state?.selectedEncounter?.pk,
        equipment_detail: state?.treatmentOrderList?.equipmentDetail,
        id: params.editId || null,
        is_advance: state?.OrderTreatmentSequence?.preOrderDate ? true : false,
        is_operation_division: false,
        is_out_perform_div: state.OrderTreatmentSequence?.preOrderCheck || false,
        note: "",
        order_detail: state?.treatmentOrderList?.orderDetail,
        order_items: params.editId ? orderItemsEdit : orderItemsAdd,
        order_status: params.orderStatus || 2,
        order_summary_detail: "",
        order_time: params.isOutPerformDiv
          ? null
          : state?.OrderTreatmentSequence?.preOrderDate
          ? orderTime
          : null,
        out_perform_div: params.isOutPerformDiv
          ? state?.OrderTreatmentSequence?.preOrderDate || ""
          : "",
        progression_cycle: state.selectedProgressCycle?.id,
        show_alert: params.isOutPerformDiv ? false : true,
        to_be_performer: state?.treatmentOrderList?.toBePerformer,
        _items: state?.OrderTreatmentSequence?.treatmentList,
        _principalDiag: [],
        _secondaryDiag: [],
        dataChanged: params.dataChanged,
      };

      const [response, error, network] = await TreatmentOrderList.create({
        data,
        apiToken: controller.apiToken,
        extra: { division: controller.data?.division },
      });

      if (response) {
        id = response.id;
      }
    }

    const [approveRes, approveErr, approveNet] = await TreatmentOrderEducationApproveView.put({
      pk: id,
      data: {
        approved_by_username: params.approved_by_username,
        approved_by_password: params.approved_by_password,
        dataChanged: params.dataChanged,
      },
      apiToken: controller.apiToken,
    });

    if (approveRes) {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "SUCCESS",
        },
        successMessage: {
          ...state.successMessage,
          [`${params.card}_${params.action}`]: approveRes,
        },
      });
      SearchAndAdd(controller, { ...params, action: "getData" });

      controller.handleEvent({
        message: "HandleRefreshEncounter" as any,
        params: {},
      });
    } else {
      controller.setState({
        buttonLoadCheck: {
          ...state.buttonLoadCheck,
          [`${params.card}_${params.action}`]: "ERROR",
        },
        errorMessage: {
          ...state.errorMessage,
          [`${params.card}_${params.action}`]: {
            error: approveErr,
            showMessage: false,
          },
        },
      });
    }
  } else if (params.action === "find_doctor_pending_task") {
    const [resp, err, netw] = await DoctorPendingTaskList.list({
      apiToken: controller.apiToken,
      params: { order_ref: params.order.id },
    });
    if (err) {
      controller.setState({
        OrderTreatmentSequence: {
          ...state.OrderTreatmentSequence,
          selectedDoctorPendingTask: null,
        },
      });
    } else {
      controller.setState({
        OrderTreatmentSequence: {
          ...state.OrderTreatmentSequence,
          selectedDoctorPendingTask: {
            order_specific_type: "TREATMENT_ORDER",
            co_signed_id: params.order.order_by,
            co_signed: params.order.order_by_name,
            order_type: params.order.order_type,
            ref_order: params.order.id,
            id: resp?.items?.[0]?.id,
          },
        },
      });
    }
  }
};

export const checkAllowEditOrder = (data: any) => {
  const allowEdit =
    (data.isStudentUser && data.order_by !== data.approved_by) || !data.isStudentUser;

  return allowEdit && data.status !== "CANCELED";
};
